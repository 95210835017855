import axios from 'axios';
import qs from 'qs';
import { isObject } from '@/utils';

const formUrl = ['/api/golddraw/query_winnerAddress', '/api/goldmall/query_goodsDetail',
  '/api/goldmall/query_exchangeRecord', '/api/address/query_manageAddress', '/api/goldmall/query_goodsList',
  '/api/goldmall/exchange_goods', '/api/address/modify_manageAddress',
  '/api/address/remove_manageAddress', '/api/golddraw/draw_prize', '/api/messageBox/product_proposal',
];

const service = axios.create({
  baseURL: '/',
  timeout: 10000, // request timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  (config) => {
    const { data, url, header } = config;
    const userId = sessionStorage.getItem('userId');
    if (formUrl.includes(url)) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (config.url.includes('publicize/getPublicize')) {
      config.params = config.data;
    } else if (formUrl.includes(url)) {
      config.data = qs.stringify(data);
    } else {
      config.data = JSON.stringify(data);
    }
    if (sessionStorage.getItem('token') && (config.url.includes('publicize/uploadClick'))) {
      config.headers.token = sessionStorage.getItem('token');
      config.headers.cUserId = sessionStorage.getItem('userId') || 'yy';
    }
    if ((url.includes('third/getJuZToken') || url.includes('cOrder/preSaveWlInfo') || url.includes('goldmall/query_goodsList')) && userId) {
      config.headers.cuserId = sessionStorage.getItem('userId');
    }
    if (header) {
      Object.keys(header).forEach((key) => {
        config.headers[key] = header[key];
      });
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (!isObject(response.data)) {
      return Promise.reject(response.data);
    }
    if (response.data.code <= 0) {
      return Promise.reject(response.data);
    }
    if (response.headers.token) sessionStorage.setItem('token', response.headers.token);
    return response.data;
  },
  (error) => {
    console.log('err' + error); // for debug
    if (typeof error.msg !== 'undefined') {
      console.log(error);
    }
    return Promise.reject(error);
  },
);

export default service;
