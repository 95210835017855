import request from '@/utils/request';

const BASE_URL = '/api';

const getUserStatus = (data) => request({
  url: BASE_URL + '/advertisement/userStatus',
  method: 'post',
  data,
});

const getPublicize = (data) => request({
  url: BASE_URL + '/publicize/getPublicize',
  method: 'post',
  data,
});

const getUploadClickAction = (data) => request({
  url: BASE_URL + '/publicize/uploadClick',
  method: 'post',
  data,
});

/** *
 * 桔子授权
 * ** */
const getJuZTokenAction = (data) => request({
  url: BASE_URL + '/app/third/getJuZToken',
  method: 'post',
  data,
});

/** *
 * 桔子监控上报
 * ** */
const getRequestApi = (data) => request({
  url: BASE_URL + '/app/third/getRequest',
  method: 'post',
  data,
});

/** **
 *  金豆商品列表
 * ** */

const getQueryGoodsList = (data) => request({
  url: BASE_URL + '/goldmall/query_goodsList',
  method: 'post',
  data,
});

/** ***
 * 金豆跑马灯
 * *** */

const getQueryWinnerRecord = (data) => request({
  url: BASE_URL + '/golddraw/query_winnerRecord',
  method: 'post',
  data,
});

/** ***
 * 金豆我的奖励
 * *** */

const getQueryWinnerAddress = (data) => request({
  url: BASE_URL + '/golddraw/query_winnerAddress',
  method: 'post',
  data,
});

/** 商品详情
 * *** */

const getExchangeGoods = (data) => request({
  url: BASE_URL + '/goldmall/exchange_goods',
  method: 'post',
  data,
});

const getQueryGoodsDetail = (data) => request({
  url: BASE_URL + '/goldmall/query_goodsDetail',
  method: 'post',
  data,
});

const getQueryExchangeRecord = (data) => request({
  url: BASE_URL + '/goldmall/query_exchangeRecord',
  method: 'post',
  data,
});

/** 获取当前地址
 * *** */

const getQueryManageAddress = (data) => request({
  url: BASE_URL + '/address/query_manageAddress',
  method: 'post',
  data,
});

/** 保存地址
 * *** */

const getModifyManageAddress = (data) => request({
  url: BASE_URL + '/address/modify_manageAddress',
  method: 'post',
  data,
});

/** 移除地址
 * *** */

const getRemoveManageAddress = (data) => request({
  url: BASE_URL + '/address/remove_manageAddress',
  method: 'post',
  data,
});

/** 物流信息预存接口
 * *** */

const getPreSaveWlInfo = (data) => request({
  url: BASE_URL + '/cOrder/preSaveWlInfo',
  method: 'post',
  data,
});

/** 保存地址接口
 * *** */

const getSaveWinnerAddress = (data) => request({
  url: BASE_URL + '/goldmall/save_winnerAddress',
  method: 'post',
  data,
});

/** 金豆抽奖接口
 * *** */

const drawPrizeApi = (data) => request({
  url: BASE_URL + '/golddraw/draw_prize',
  method: 'post',
  data,
});

const productProposalApi = (data, header) => request({ // 用户反馈
  url: BASE_URL + '/messageBox/product_proposal',
  method: 'POST',
  data,
  header,
});

export {
  getUserStatus, getPublicize, getUploadClickAction,
  getJuZTokenAction, getQueryGoodsList,
  getQueryWinnerRecord, getRequestApi, getQueryWinnerAddress,
  getExchangeGoods, getQueryGoodsDetail, getQueryExchangeRecord,
  getQueryManageAddress, getModifyManageAddress,
  getRemoveManageAddress, getPreSaveWlInfo, getSaveWinnerAddress,
  drawPrizeApi, productProposalApi,
};
